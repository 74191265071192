import {
	Directive,
	HostListener,
	ElementRef,
	Injector,
	Optional,
	AfterViewInit
} from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { fromEvent, Subject, takeUntil, debounceTime, take, map } from "rxjs";

import { InvalidControlScrollContainerDirective } from "./invalid-control-scroll-container.directive";


@Directive({
	selector: '[moneyInvalidFormScroll]',
	standalone: true
})
export class InvalidFormScrollDirective {

	private _unsubscribeAll$:Subject<boolean> = new Subject<boolean>();

	private get containerEl(): HTMLElement {
		return this.scrollContainerDir.containerEl
	}

	constructor(
		private el: ElementRef,
		private formGroupDir: FormGroupDirective,
		@Optional()
		private scrollContainerDir: InvalidControlScrollContainerDirective
	) {

  }


	@HostListener("ngSubmit") onSubmit() {
		if (this.formGroupDir.control.invalid) {
			this.scrollToFirstInvalidControl();
		}
	}

	private scrollToFirstInvalidControl() {
		const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
			".form-control.ng-invalid"
		);

		//this.containerEl.scrollTo({ behavior: 'smooth'});
		// this.containerEl.scrollIntoView({
		// behavior: "smooth"
		// });

		this.containerEl.scroll({
			top: this.getTopOffset(firstInvalidControl),
			left: 0,
			behavior: 'smooth',
		});

    // const scroll$ = fromEvent(this.containerEl, 'click').pipe(map(() => this.containerEl));
		// scroll$.pipe(
		// 	takeUntil(this._unsubscribeAll$),
		// 	debounceTime(100)).subscribe(element => {
		// 	firstInvalidControl.focus()
		// });

		// fromEvent(this.containerEl, "scroll")
		// .pipe(
		// takeUntil(this._unsubscribeAll$),
		// debounceTime(100),
		// )
		// .subscribe(() => {
		// debugger
		// firstInvalidControl.focus()
		// });
	}

	private getTopOffset(controlEl: HTMLElement): number {
		const labelOffset = 50;
		const controlElTop = controlEl.getBoundingClientRect().top;

		if (this.scrollContainerDir) {
			console.log(this.containerEl);
			console.log(this.containerEl.getBoundingClientRect());
			const containerTop = this.containerEl.getBoundingClientRect().top;
			const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;

			return absoluteControlElTop - containerTop - labelOffset;
		} else {
			const absoluteControlElTop = controlElTop + window.scrollY;

			return absoluteControlElTop - labelOffset;
		}
	}
}
