import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputRestriction]',
  exportAs  : 'appInputRestriction',
  standalone: true,
})
export class InputRestrictionDirective {
  @Input('appInputRestriction') appInputRestriction!:
    | 'integer'
    | 'alphabets'
    | 'noSpecialChars'
    | 'allowNumberWithDot'
    | 'allowAlphaNumeric'
    | 'amount';
  @Input('isSpaceAllow') isSpaceAllow: boolean = false;
  @Input('isCommaAllow') isCommaAllow: boolean = false;
  arabicRegex = '[\u0600-\u06FF]';

  constructor(private _el: ElementRef, private _renderer: Renderer2) { }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {

    if (this.appInputRestriction === 'integer') {
      this.integerOnly(event);
    } else if (this.appInputRestriction === 'alphabets') {
      this.alphabetsOnly(event);
    } else if (this.appInputRestriction === 'noSpecialChars') {
      this.noSpecialChars(event);
    }else if(this.appInputRestriction === 'allowNumberWithDot'){
      this.numberWithDot(event);
    }
    else if(this.appInputRestriction === 'allowAlphaNumeric'){
      this.alphaNumeric(event);
    }
  }

  integerOnly(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    if (
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1
    ) {
      e.preventDefault();
    }
  }

  alphabetsOnly(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      (this.isSpaceAllow && k === 32)
    ) {
      return;
    }
    e.preventDefault();
  }
  alphaNumeric(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      (k > 47 && k < 58)||
      k === 8 ||
      (this.isSpaceAllow && k === 32)||
      (this.isCommaAllow && k === 44)
    ) {
      return;
    }
    e.preventDefault();
  }
  numberWithDot(event: KeyboardEvent) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if (
      (k > 47 && k < 58) ||
      k === 46
    ) {
      return;
    }
    e.preventDefault();
  }
  noSpecialChars(event: KeyboardEvent) {
    // console.log(event);

    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      (k >= 48 && k <= 57) ||
      (this.isSpaceAllow && k === 32)
    ) {
      return;
    }
    e.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    let regex: any;
    if (this.appInputRestriction === 'integer') {
      regex = /[0-9]/g;
    } else if (this.appInputRestriction === 'noSpecialChars') {
      regex = /[a-zA-Z0-9 ]/g;
    } else if (this.appInputRestriction === 'alphabets') {
      regex = /[a-zA-Z ]/g;
    }

    const e: any = <ClipboardEventInit>event;
    const pasteData = (e.clipboardData as DataTransfer).getData('text/plain');

    let m;
    let matches = 0;
    while ((m = regex.exec(pasteData)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      // The result can be accessed through the `m`-variable.
      m.forEach((match: any, groupIndex: number) => {
        matches++;
      });
    }
    if (matches === pasteData.length) {
      return;
    } else {
      e.preventDefault();
    }
  }
}
