import { Observable, of } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MoneyConfirmationConfig } from './confirmation.types';
import { merge } from 'lodash-es';
import { MoneyDialogComponent } from './dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MoneyConfirmationDialogService {

  private _matDialog: MatDialog = inject(MatDialog);
  private _defaultConfig: MoneyConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'exclamation-triangle',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: false,
        label: 'Confirm',
        color: 'warn',
      },
      cancel: {
        show: false,
        label: 'Cancel',
      },
    },
    dismissible: false,
  };

  /**
   * Constructor
   */
  constructor() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: MoneyConfirmationConfig = {}): MatDialogRef<MoneyDialogComponent> {
    // Merge the user config with the default config
    const userConfig: any = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(MoneyDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'restrict-modal',
      maxWidth: '550px',
      width: '100%'
    });
  }

  _close(): Observable<any> {
    this._matDialog.closeAll()
    return of(true);
  }
}
