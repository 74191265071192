import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
// -----------------------------------------------------------------------------------------------------
// @ Bounce
// -----------------------------------------------------------------------------------------------------
const bounce = trigger('bounce',
  [
    state('active', style({ transform: 'translateY(0)' })),
    transition('void => false', []),
    transition('void => *, * => true', [
      sequence([
        style({ transform: 'translateY(0)' }),
        animate(
          '400ms cubic-bezier(0,0,0,1)',
          style({ transform: 'translateY(-14px)' })
        ),
        animate(
          '300ms cubic-bezier(1,0,1,1)',
          style({ transform: 'translateY(0)' })
        ),
        animate(
          '200ms cubic-bezier(0,0,0,1)',
          style({ transform: 'translateY(-10px)' })
        ),
        animate(
          '150ms cubic-bezier(1,0,1,1)',
          style({ transform: 'translateY(0)' })
        ),
        animate(
          '100ms cubic-bezier(0,0,0,1)',
          style({ transform: 'translateY(-5px)' })
        ),
        animate(
          '80ms cubic-bezier(1,0,1,1)',
          style({ transform: 'translateY(0)' })
        ),
      ]),
    ]),
  ]);
export { bounce };
