import { APP_INITIALIZER, ENVIRONMENT_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MONEY_CONFIG } from './services/config/config.constants';
import { PlatformService } from './services/platform';
import { MoneyConfig } from './services/config/config.types';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from './services/dialog/dialog.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from './services/toster/snackbar.service';
import { MoneySplashScreenService } from './services/splash-screen';

export type MoneyProviderConfig = {
	money?: MoneyConfig
}

export const provideMoney = (config: MoneyProviderConfig): Array<Provider | EnvironmentProviders> => {
	const providers: Array<Provider | EnvironmentProviders> = [
		{
			// Disable 'theme' sanity check
			provide: MATERIAL_SANITY_CHECKS,
			useValue: {
				doctype: true,
				theme: false,
				version: true,
			},
		},
		{
			// Use the 'fill' appearance on Angular Material form fields by default
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'fill',
			},
		},
		{
			provide: MONEY_CONFIG,
			useValue: config?.money ?? {},
		},
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(PlatformService),
			multi: true,
		},
		{
			provide : ENVIRONMENT_INITIALIZER,
			useValue: () => inject(MoneySplashScreenService),
			multi : true,
	  },
		importProvidersFrom(MatDialogModule),
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(DialogService),
			multi: true,
		},
		importProvidersFrom(MatSnackBarModule),
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(SnackbarService),
			multi: true,
		},
	]
	return providers;
}
