<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout and home layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->

  <money-loading-bar></money-loading-bar>
  <empty-layout *ngIf="layout === 'empty'"></empty-layout>
<div class="clearfix" [ngClass] = "layout === 'home' ? 'dashboard' : ''" #tagsSideMenuPanel>
  <home-layout *ngIf="layout === 'home'"></home-layout>
</div>

