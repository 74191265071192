import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { merge } from 'lodash-es';
import { MONEY_CONFIG } from './config.constants';

@Injectable({
  providedIn: 'root'
})
export class MoneyConfigService {
  private _config: BehaviorSubject<any>;

  constructor(@Inject(MONEY_CONFIG) config: any) {
    this._config = new BehaviorSubject(config);
  }

  /**
    * Setter for config
  */
  set config(value: any) {
    const config = merge({}, this._config.getValue(), value);
    this._config.next(config);
  }

  /**
   * getter for config
   */
  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  /**
   * Resets the config to the default
   */
  reset(): void {
    this._config.next(this.config);
  }
}
