import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputRestrictionDirective } from 'src/@money/directives';
import { SnackbarService } from 'src/@money/services/toster/snackbar.service';
import { CommonService } from 'src/app/core/service/common/common.service';
import { TravellerOrderService } from '../../../travaller-order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-customer-order',
  templateUrl: './create-customer-order.component.html',
  styleUrls: ['./create-customer-order.component.scss'],
  standalone:true,
  imports: [NgIf, ReactiveFormsModule, InputRestrictionDirective, MatFormFieldModule, MatInputModule]
})
export class CreateCustomerOrderComponent implements OnInit{
  registerForm: UntypedFormGroup = this._fb.group({
    name: ['', [Validators.required]],
    phone_no: ['', [Validators.required, Validators.pattern('^[6789][0-9]{9}')]],
  })
  type:string;
  constructor(
    public _matDialogRef: MatDialogRef<CreateCustomerOrderComponent>,
    private _fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private _toaster:SnackbarService,
    private _travallerOrdeService: TravellerOrderService,
    private _router:Router
  ) { }
  ngOnInit(): void {
    // console.log(this.data);

    if (this.data) {
      this.type=this.data?.type || 'login'
      this.registerForm.get('phone_no')?.setValue(this.data?.mobile);
    }
  }
  createUser() {
    if (this.registerForm.invalid) {
      this._commonService.markFormGroupTouched(this.registerForm);
      return;
    }
      const _data = { mobile: this.registerForm.get('phone_no')?.value, name: this.registerForm.get('name')?.value };
    this._travallerOrdeService._createUser(_data).subscribe(res => {
      if (res.code == 200) {
        this._toaster.success(res.message);
        this.close(res.data);
      } else {
        this._toaster.error(res.message);
      }
    })
  }
  backToDashBoard(){
    this._router.navigate(['dashboard/landing']);
    this._matDialogRef.close();
  }
  close(data?:any): void {
    this._matDialogRef.close(data);
  }
}
