<div class="modal-content">
  <div class="modal-header text-center pb-0">
    <!-- <button type="button" class="close" (click)="close()">×</button> -->
    <div class="logo">
      <img src="assets/img/logo.png" alt="logo" />
    </div>
    <hr class="gradient mb-0" />
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <form [formGroup]="registerForm">
      <div class="form-group hs-normal">
        <label>Name</label>
        <div class="input col px-0">
          <mat-form-field>
            <input
              matInput
              type="text"
              class="form-control"
              placeholder="Enter Name"
              [formControlName]="'name'"
              [appInputRestriction]="'alphabets'" [isSpaceAllow]="true"

            />
            <mat-error *ngIf="registerForm.get('name')?.hasError('required')"
              >This field is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="form-group hs-number">
        <label>Phone Number</label>
        <div class="input">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Enter Mobile Number"
              [formControlName]="'phone_no'"
              [appInputRestriction]="'integer'"
              maxlength="10"
            />
            <div matPrefix>
              <img src="assets/img/ind.png" alt="flag" /> +91
              <i class="fas fa-angle-down mx-2"></i>
            </div>
            <mat-error
              *ngIf="registerForm.get('phone_no')?.hasError('required')"
              >This field is required</mat-error
            >
            <mat-error *ngIf="registerForm.get('phone_no')?.hasError('pattern')"
              >Invalid Phone no.</mat-error
            >
          </mat-form-field>
        </div>
      </div>


      <div class="actions">
        <button
          type="button"
          class="btn primary-btn w-100"
          (click)="createUser()"
        >
          Submit
        </button>
      </div>
      <div class="botom-text">
        <a (click)="backToDashBoard()" >Back To Dashboard</a>
      </div>
    </form>

  </div>
</div>

