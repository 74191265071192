import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { endpoint } from "src/app/core/service/endpoints";
import {  Observable, catchError, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  _getOrderDetail(payload:{user_id:number,order_id?:number}): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.getOrder}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }


  _sendApiReference(payload:any): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.sendApiReference}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  _addUpdateOrder(payload:any): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.updateOrder}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  _deleteOrder(payload:any): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.deleteOrder}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  _createFirstOrder(payload:any): Observable<any>{
   return this._httpClient.post(`${endpoint.order.createOrder}`, payload).pipe(
      catchError((err) =>
        of(err),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  getTravellerWiseRelation(): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.travellerRelationList}`, null).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  getTravellerName(jsonReq:{order_id:number,relation_id:number,relation_with:number}): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.getNames}`, jsonReq).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  getPurposeList(jsonReq:{product_type_id:number}): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.getPurposeList}`, jsonReq).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  countTraveller(dataArray:any){
   const fcceSell = dataArray.filter((d:any)=> (d.controls['product_id'].value < 3 &&(d.controls['product_type_id'].value == 2 || d.controls['product_type_id'].value == 5)));
   const fccebuy = dataArray.filter((d:any)=> (d.controls['product_id'].value < 3 &&(d.controls['product_type_id'].value != 2 && d.controls['product_type_id'].value != 5)));
   const mtBuy = dataArray.filter((d:any)=> (d.controls['product_id'].value == 3));

   return {buy:fccebuy.length+mtBuy.length,sell:fcceSell.length};
  }

  checkOrderExistOrNot(singeOrderDetail:any,travellerDetail:any):Promise<any>{
    let _travellerDetail:any=travellerDetail.getRawValue();
    return new Promise((resolve,reject)=>{
      if(_travellerDetail.product_id != 3){
        let currencyIdMatchCount = 0;
        const _selectedtravellerDetail=singeOrderDetail.controls['travellerDetail'].controls.filter((t:any)=> t.controls['traveller_id'].value == _travellerDetail.traveller_id);
        let filterArray:any=[];
        _selectedtravellerDetail.forEach((element:any) => {
          let filterObj:any={};
          for (const key in element.controls) {
            filterObj[key]=element.controls[key].value
          }
          filterArray.push(filterObj);
        });
          filterArray=filterArray.filter((d:any)=> d.id && (d.id != _travellerDetail.id))||[];
        if(!filterArray.length){
          resolve({isCurrencyExist:false,isPurposeExist:false,addOrder:true});
        }else{
          if (filterArray.length) {
            if (_travellerDetail.product_id != 3) {
                const ce = filterArray.filter((t:any)=> t.product_id == 1 );//check currency exchange order exist
                const fc = filterArray.filter((t:any)=> t.product_id == 2 );  //check forex card order exist
                const isCurrencyExist=filterArray.filter((t:any)=> t.currency_id == _travellerDetail.currency_id );
                const isNewCardExist = filterArray.filter((e: any) => e.product_id == 2 && e.product_type_id == 3);
                const isReloadExist = filterArray.filter((e: any) => e.product_id == 2 && e.product_type_id == 4);
                const isUnloadCardExist = filterArray.filter((e: any) => e.product_id == 2 && e.product_type_id == 5);
                if ((((ce?.length && fc?.length && isNewCardExist?.length) || (fc?.length && isNewCardExist?.length))
                && _travellerDetail.product_type_id > 3) || ((isReloadExist.length || isUnloadCardExist.length)
              && _travellerDetail.product_type_id == 3)) {
                  resolve({isCurrencyExist:false,addOrder:false,unloadWithNewCard:true});
                  return;
                }

                for (const iterator of filterArray) {
                  if ((ce?.length && fc?.length) || (fc?.length && _travellerDetail.product_id == 2)) {
                    if (_travellerDetail.product_type_id == 3 && (_travellerDetail.product_id == 1 || _travellerDetail.product_id == 2)) {
                      if (_travellerDetail.currency_id == iterator.currency_id && _travellerDetail.product_type_id == iterator.product_type_id) {
                        currencyIdMatchCount = currencyIdMatchCount + 1;
                        resolve({isCurrencyExist:true,addOrder:false});
                        break;
                      }
                      // else if (_travellerDetail.product_type_id == 3 && iterator.currency_id && isNewCardExist.length && _travellerDetail.product_id == iterator.product_id) {
                      //   resolve({isCurrencyExist:false,isNewCardExist:true,addOrder:false});
                      //   return;
                      // }
                    } else if (_travellerDetail.product_id != 3) {
                      if (_travellerDetail.currency_id == iterator.currency_id && _travellerDetail.product_type_id == iterator.product_type_id && (isCurrencyExist.length && !_travellerDetail.id)) {
                        currencyIdMatchCount = currencyIdMatchCount + 1;
                        resolve({isCurrencyExist:_travellerDetail.id?false:true,addOrder:_travellerDetail.id?true:false });
                        break
                      }
                    }
                  }
                  else if (_travellerDetail.product_id == 1) {
                    if (_travellerDetail.product_type_id == 1 || _travellerDetail.product_type_id == 2) {
                      if (_travellerDetail.currency_id == iterator.currency_id && _travellerDetail.product_type_id == iterator.product_type_id && (isCurrencyExist.length && !_travellerDetail.id)) {
                        currencyIdMatchCount = currencyIdMatchCount + 1;
                        resolve({isCurrencyExist:_travellerDetail.id?false:true,addOrder:_travellerDetail.id?true:false });
                        break
                      }
                    }
                  } else if (_travellerDetail.product_id == 2) {
                    if (_travellerDetail.product_type_id == 3) {
                      if (_travellerDetail.currency_id == iterator.currency_id && _travellerDetail.product_type_id == iterator.product_type_id && (isCurrencyExist.length && !_travellerDetail.id) ) {
                        currencyIdMatchCount = currencyIdMatchCount + 1;
                        resolve({isCurrencyExist:_travellerDetail.id?false:true,addOrder:_travellerDetail.id?true:false });
                        break
                      }
                      //  else if (_travellerDetail.product_type_id == 3 && iterator.currency_id && isNewCardExist.length) {
                      //   resolve({isCurrencyExist:false,isNewCardExist:true,addOrder:false});
                      //   return
                      // }
                    } else if (_travellerDetail.product_type_id == 4 || _travellerDetail.product_type_id == 5) {
                      if (_travellerDetail.currency_id == iterator.currency_id && _travellerDetail.product_type_id == iterator.product_type_id) {
                        currencyIdMatchCount = currencyIdMatchCount + 1;
                        resolve({isCurrencyExist:true,isNewCardExist:false,addOrder:false});
                        break
                      }
                    }

                  }
                }

            }
          }
        }
        if(!currencyIdMatchCount){
          resolve({isCurrencyExist:false,isPurposeExist:false,addOrder:true});
        }
      }else{
       const _selectedtravellerDetail=singeOrderDetail.controls['travellerDetail'].controls.filter((t:any)=> t.controls['relation_id'].value == _travellerDetail.relation_id);
      let filterArray:any=[];
      _selectedtravellerDetail.forEach((element:any) => {
        let filterObj:any={};
        for (const key in element.controls) {
          filterObj[key]=element.controls[key].value
        }
        filterArray.push(filterObj);
      });
      if(filterArray.length > 1){
        if(_travellerDetail.relation_id != 1 && _travellerDetail.relation_id != 2 && _travellerDetail.relation_id != 3){
          let _name = (_travellerDetail.traveller_name.toLowerCase()).trim();
          const _gardiansList=filterArray.filter((t:any)=> (t.traveller_name).toLowerCase().trim() == _name && t.relation_id ==  _travellerDetail.relation_id);
          if(_gardiansList.length > 1){
            resolve({isremitterExist:true,addOrder:false});
            return;
          }else{
            resolve({addOrder:true});
          }
        }else{
          resolve({isremitterExist:true,addOrder:false});
					return;
        }
      }else{
        resolve({addOrder:true});
      }
      }
		})
	}

  checkMarkupAmount(obj:any):boolean{
    let result:boolean=false;
    const objValue = obj.getRawValue();
    const maxmarkUp = Number(objValue.old_rate)*Number(objValue.rate_markup_percentage)/100;
    const newRate = Number(objValue.old_rate) + Number(maxmarkUp.toFixed(2));
    if(newRate >= objValue.rate){
      result=true
    }
    return result;
  }
  getMarkUpWithRate(obj:any):number{
    const objValue = obj.getRawValue();
    const maxmarkUp = Number(objValue.old_rate)*Number(objValue.rate_markup_percentage)/100;
    const newRate = Number(objValue.old_rate) + Number(maxmarkUp.toFixed(2));
    return newRate;
  }

  getCommission(objData:any):number{
    let returnData:any;
    if(objData.controls['rate'].value){
      const commission = (Number(objData.controls['rate'].value) - Number(objData.controls['old_rate'].value)).toFixed(2);
      returnData = Number(commission)*Number(objData.controls['forex_amt'].value)
    }else{
      returnData=0;
    }
    return returnData;

  }
  getPayeeName(jsonReq:{traveller_id:number,relation_id:number}): Observable<any> {
    return this._httpClient.post(`${endpoint.orderDetail.getPayeeNames}`, jsonReq).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
}
