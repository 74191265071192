import { InjectionToken } from '@angular/core';
export interface ResponseTable {
	data: [];
	header: [];
	per_page: number;
	total_records: number;
}


export interface TableColumn {
	name: string;
	value: string;
	show_checkbox?: boolean;
	is_show?: number;
	issort?: number;
}

export interface PageConfig {
	search: boolean;
	short?: boolean;
	excel?: boolean;
  commission?:boolean;
  refresh?:boolean;
  date:boolean;
  redirect?: {
    show: boolean,
    route: string
  }
}


export const PAGE_CONFIG = new InjectionToken<PageConfig>('Table_Page_Config');


export interface TableConstant {
  tableBadges: any
  IconClasses: any
  statsBages: any
}
export let tableConstants: TableConstant = {
  tableBadges: [
    {
      name: 'pending',
      class: 'pending'
    },
    {
      name: 'Completed',
      class: 'verified'
    },
    {
      name: 'Rejected',
      class: 'rejected'
    },
    {
      name: 'Verified/scheduled',
      class: 'verified'
    },
    {
      name: 'Confirmed',
      class: 'verified'
    }
  ],



  statsBages: [
    {
      name: 'Deactive',
      class: 'rejected'
    },
    {
      name: 'Active',
      class: 'verified'
    }
  ],


  IconClasses: [
    {
      name: 'add',
      img: '/assets/img/plus-green.svg'
    },
    {
      name: 'edit',
      img: '/assets/img/edit.svg'
    },
    {
      name: 'view',
      img: '/assets/img/eye-green.svg'
    },
    {
      name: 'delete',
      img: '/assets/img/delete-red.svg'
    },
    {
      name: 'status',
      img: '/assets/img/delete-red.svg'
    },
    {
      name: 'accept',
      img: '/assets/img/check-green.svg'
    },
    {
      name: 'reject',
      img: '/assets/img/xmark-solid.svg'
    },
    {
      name: 'invoice',
      img: '/assets/img/file-earmark-arrow-down.svg'
    },
    {
      name: 'delivery',
      img: '/assets/img/truck-delivery.svg'
    },
    {
      name: 'upload_file',
      img: '/assets/img/file-earmark-arrow-down.svg'
    },
  ]
}
