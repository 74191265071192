import { BehaviorSubject, catchError, Observable, of, switchMap, throwError ,mergeMap} from 'rxjs';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { CryptoUtils } from 'src/@money/crypto/crypto.utils';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../service/user/user.service';
import { endpoint } from '../service/endpoints'
import { SnackbarService } from 'src/@money/services/toster/snackbar.service';
import { CommonService } from '../service/common/common.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() modalValue = new EventEmitter<string>();
  walletBalanceData: BehaviorSubject<any> = new BehaviorSubject('');
  _authenticated: boolean = false;
  _getUserLocation: any = {}
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _toster: SnackbarService,
    private _commonService: CommonService,
    private _router: Router,
  ) {

  }
  popUpModelfunc(value: string, obj?: {}) {
    const type: any = { type: value, obj: obj }
    this.modalValue.emit(type);
  }


  get accessToken(): string {
    return CryptoUtils.getItem('auth_token')?.authtoken ?? '';
  }

  /**------------------------------------------------------------------------
   **                            USER CREDENTIALS
   *------------------------------------------------------------------------**/



  isMobileExist(credentials: { mobile: string }): Observable<any> {
    return this._httpClient.post(`${endpoint?.login.isMobileExist}`, credentials).pipe(
      catchError((err) =>
        of(err),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  checkLogin(credentials: { email: string, type: number, password: string, lng: string, lat: string }): Observable<any> {
    return this._httpClient.post(`${endpoint?.login.verifyLogin}`, credentials).pipe(
      catchError((err) =>
        of(err),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  VerifyOtp(payLoad_: any): Observable<any> {
    return this._httpClient.post(`${endpoint?.login.verifyOtp}`, payLoad_).pipe(
      catchError((err) =>
        of(err),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  _bookOrder(mobile: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const data_: any = { mobile: mobile };
      this.isMobileExist(data_).subscribe(res => {
        if (res.code == 200) {
          if (res.is_user_exist != 1) {
            this.openModel('register', data_);
          } else {
            data_.name = res.data.fullname;
            data_.type = 'login';
            // this.sendOtp(data_).subscribe(res => {
            //   if (res.code == 200) {
            //     this._toster.success(res.message);
            //     this.openModel('otp', data_);
            //   } else {
            //     this._toster.error(res.message);
            //   }
            // })
          }
          resolve(true);
        } else {
          this._toster.warning(res.message)
          reject(false)
        }
      })
    })
  }
  // createOrder(): Observable<any> {
  //   const reqJson = { order_detail: '', city_id: '' };
  //   const _orderDetail = CryptoUtils.getItem('_reqOrderDetail');
  //   if (_orderDetail && _orderDetail?.orderDetail.length) {
  //     this._commonService.city$.subscribe((res: any) => {
  //       if (res) {
  //         reqJson.city_id = res.city_id;
  //       }
  //     });
  //     reqJson.order_detail = _orderDetail.orderDetail;
  //   }
  //   return this._httpClient.post(`${endpoint.order.createOrder}`, reqJson).pipe(
  //     catchError((err) =>
  //       of(err),
  //     ),
  //     switchMap((response: any) => {
  //       return of(response);
  //     }),
  //   );
  // }
  openModel(componentName: any, data?: any): void {
    this.popUpModelfunc(componentName, data);
  }

  /**========================================================================
   **                            Check User Is Login Or Not
   *========================================================================**/

  async _checkUserAuth(): Promise<any> {
    const check: any = await CryptoUtils.getItem('auth_token');
    if (check) {
      this._userService.setAuth = {
        name: check.name,
        mobile: check.mobile,
        sideMenu: check.role_permission,
        account_number:check.account_number,
      }
    }
  }

  signOut(): Observable<any> {
    return this._httpClient.post(`${endpoint?.login.logOut}`, null).pipe(
      catchError((err) =>
        of(err),
      ),
      mergeMap((response: any) => {
        this._userService.setAuth = {};
        CryptoUtils.clearStorage();
        return of(true);
      }),
    );
  }

  /**
    * Check the authentication status
  */
  check(): Observable<any> {
    const check: any = this.getToken();
    let authenticated = true
    if (check) {
      authenticated = true
    }
    if (!check) {
      authenticated = false;
    }
    return of(authenticated);
  }

  private getToken(): boolean {
    return CryptoUtils.getItem('auth_token')?.authtoken ? true : false;
  }
  get _walletBalanceData() {
    return this.walletBalanceData.asObservable();
  };
  static _walletAmount() {
    this.instance.walletAmount();
  }
  static instance: AuthService;
 async walletAmount() {
      this._httpClient.post(`${endpoint?.wallet.getWalletAmount}`, null).pipe(
        catchError((err) =>
          of(err),
        ),
        switchMap((response: any) => {
          return of(response);
        }),
      ).subscribe(res=>{
        if(res.code == 200){
          this.walletBalanceData.next(res.data);
        }else{
          this.walletBalanceData.next('');
          this._toster.error(res.message);
        }
      });
  };
}
