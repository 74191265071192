import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
export const initialDataResolver = () => {
  const auth = inject(AuthService);

  // Fork join multiple API endpoint calls to wait all of them to finish
  return forkJoin([
    auth._checkUserAuth()
  ]);
};
