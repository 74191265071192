import { inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

export class CryptoUtils {

  /**
   * Constructor
  */
  static CryptoJSAesJson = {
    stringify: (cipherParams: any) => {
      const vbJsonString: any = {
        hv: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
      };
      if (cipherParams.iv) {
        vbJsonString['iv'] = cipherParams.iv.toString()
      };
      if (cipherParams.salt) {
        vbJsonString['s'] = cipherParams.salt.toString()
      };
      return JSON.stringify(vbJsonString);
    },
    parse: (jsonStr: string) => {
      const vbJsonParse = JSON.parse(jsonStr);
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(vbJsonParse.hv)
      });
      if (vbJsonParse.iv) {
        cipherParams['iv'] = CryptoJS.enc.Hex.parse(vbJsonParse.iv)
      }
      if (vbJsonParse['s']) {
        cipherParams.salt = CryptoJS.enc.Hex.parse(vbJsonParse.s)
      }
      return cipherParams;
    }
  }

  constructor() {
  }

  /**
   * Generate a globally Encrypt
  */

  static Encrypt = (data: any): string => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'waAgexGyN', { format: this.CryptoJSAesJson }).toString();
  }

  /**
  * Generate a globally Decrypt
 */

  static Decrypt = (data: any): string => {
    return JSON.parse(CryptoJS.AES.decrypt(JSON.stringify(data), 'waAgexGyN', { format: this.CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
  }

  static setItem = (key: string, item: any): void => {
    const value = this.encryptUsingAES(item, true);
    sessionStorage.setItem(key, value);
  };

  // getting item from session storage
  static getItem = (key: string): any => {
    const encryptedData: any = sessionStorage.getItem(key);
    if (typeof encryptedData != 'undefined' && encryptedData !== '' && encryptedData !== null) {
      const data = this.decryptUsingAES(encryptedData);
      return data ? JSON.parse(data) : false;
    } else {
      return false;
    }
  };

  // clearing everything from session storage
  static clearStorage = (): void => {
    sessionStorage.clear();
  };

  // removing an item from session storage
  static removeItem = (key: string) => {
    sessionStorage.removeItem(key);
  };

  static encryptUsingAES = (res: any, typeObj?: boolean, key: string = 'DoverDigitalHydr'): string => {
    const data = typeObj ? JSON.stringify(res) : res;
    const hash = CryptoJS.MD5(key).toString();
    const encrypted = CryptoJS.AES.encrypt(data, hash);
    return encrypted.toString();
  }

  static decryptUsingAES = (encrypted: string, key: string = 'DoverDigitalHydr'): string => {
    const hash = CryptoJS.MD5(key).toString();
    const decrypted = CryptoJS.AES.decrypt(encrypted, hash);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
