import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, finalize, Observable, throwError, take, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { MoneyLoadingService } from 'src/@money/services/loading';
import { getFormData } from './money.constant';

export const moneyApiInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const loadingService = inject(MoneyLoadingService);
  let handleRequestsAutomatically = false;

  loadingService.auto$
    .pipe(take(1))
    .subscribe((value) => {
      handleRequestsAutomatically = value;
    });

  // If the Auto mode is turned off, do nothing
  if (!handleRequestsAutomatically) {
    return next(req);
  }

  // Set the loading status to true

  /**========================================================================
   **                            Url Setup and body
   *========================================================================**/
  Object.assign(req, { url: req.urlWithParams.indexOf("assets") == -1 ? `${environment.baseurl}/${req.url}` : `${req.url}` });
  //Object.assign(req, { body: getFormData(req.body) });

  loadingService._setLoadingStatus(true, req.url);

  let newReq = req.clone();
  if (authService.accessToken) {
    newReq = req.clone({
      headers: new HttpHeaders({
        'Authorization': "Bearer " + authService.accessToken,
      })
    });
  }
  // Response
  return next(newReq).pipe(tap(evt => {
    if (evt instanceof HttpResponse) {
      if (evt != null) {
        loadingService._setLoadingStatus(false, req.url);
      }
    }
  }),
    catchError((error) => {
      // Catch "401 Unauthorized" responses
      loadingService._setLoadingStatus(false, req.url);
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        authService.signOut().subscribe((res: boolean) => {
          location.reload();
        });
        // Reload the app
      }
      return throwError(error);
    }),
  );
}
