import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { User } from './user.types';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  onSideMenuClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  constructor() { }

 /**========================================================================
  **                            SETTER AND GETTER FOR USER LOGIN
  *========================================================================**/

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set setAuth(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }
  set sideMenu(value: any) {
    this.onSideMenuClick.next(value);
  }

  /**
   * getter for sideMenu
   */
  get sideMenu$(): Observable<any> {
    return this.onSideMenuClick.asObservable();
  }
}
