import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
  /**
   * Constructor
   */
  constructor() {
    const domSanitizer = inject(DomSanitizer);
    const matIconRegistry = inject(MatIconRegistry);

    // Register icon sets
    matIconRegistry.addSvgIcon("currencyexchange", domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/currency.svg'))
    matIconRegistry.addSvgIcon("forexcard", domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/forexcard.svg'))
    matIconRegistry.addSvgIcon("moneytransfer", domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/moneytransfer.svg'))
  }
}
