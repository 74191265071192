import { Component,OnDestroy,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { TravellerOrderService } from '../traveller-order/travaller-order.service';
import { CreateCustomerOrderComponent } from '../traveller-order/travaller-order/order-details/create-customer-order/create-customer-order.component';
import { CryptoUtils } from 'src/@money/crypto/crypto.utils';
// import { Subject, filter, takeUntil,catchError ,throwError} from 'rxjs';
// import { TravellOrderUtils } from '../traveller-order/travaller-order/travell-order.utils';
// import { CookieService } from 'ngx-cookie-service';
import { OrderDetailService } from '../traveller-order/travaller-order/order-details/order-detail.service';
import { SnackbarService } from 'src/@money/services/toster/snackbar.service';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
  standalone:true
})
export class NewOrderComponent implements OnInit, OnDestroy{

  constructor(
    private _route: Router,
    private _matDialog: MatDialog,
    // private _travellerOrderService:TravellerOrderService,
    // private _cookieService:CookieService,
    private _toster: SnackbarService,
    private _orderDetailService:OrderDetailService
  ){}
  ngOnInit(): void {
    // const citySelectExists: boolean = this._cookieService.check('agent_city_select');
    // if(!citySelectExists){
    //   this._cityList()
    // }
    this.openModal();
  }
  openModal() {
    const dialogRef = this._matDialog.open(CreateCustomerOrderComponent, {
      panelClass: 'register-modal',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        CryptoUtils.setItem('user_id',result.id);
        // this.createFirstOrder(result.id);
        this._route.navigate(['dashboard/order/order-detail']);
      }
    });
  }
  createFirstOrder(userId: any) {
		const finalobj = { user_id: userId,order_detail:[] }
		this._orderDetailService._createFirstOrder(finalobj).subscribe((res: any) => {
			if (res && res.code == 200) {
				// this._toster.success(res.message);
        this._route.navigate(['dashboard/order/order-detail']);

			} else {
				this._toster.error(res.message);
			}
		})
	}
  // _cityList(): void {
  //   this._travellerOrderService.checkCityList().pipe(
  //     catchError(() => {
  //       return throwError(() => new Error('Api is Not Working'));
  //     })
  //   ).subscribe({
  //     next: (res: any) => {
  //       this.setCookies = {
  //         key: 'relations',
  //         data: res.relations,
  //       }
  //       const loginData=CryptoUtils.getItem('auth_token');
  //       const filterCookies = TravellOrderUtils.filterList(res.data, Number(loginData.city));
  //         this.setCookies = {
  //           key: 'agent_city_select',
  //           data: filterCookies,
  //         }
  //     },
  //     error: (err) => {
  //       console.log(err);
  //     },
  //   })
  // }

  // set setCookies(obj: { key: string, data: any, days?: number }) {
  //   const content = CryptoUtils.encryptUsingAES(obj.data, true);
  //   this._cookieService.set(obj.key, content, obj.days);
  // }

  // get readCookies() {
  //   const citySelectExists: boolean = this._cookieService.check('agent_city_select');
  //   const selected_city: any = CryptoUtils.decryptUsingAES(this._cookieService.get('agent_city_select'));
  //   return { select: citySelectExists, city: selected_city }
  // }

  ngOnDestroy(): void {
    CryptoUtils.removeItem('order_id')
  }
}
