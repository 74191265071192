
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  private _location: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() {
    this.getGeoLocationService();
  }

  /**
   * Getter for data
   */
  get location$(): Observable<any> {
    return this._location.asObservable();
  }

  getGeoLocationService(): void {
    navigator.geolocation.getCurrentPosition(res => {
      this._location.next({ lat: res.coords.latitude, long: res.coords.longitude });
    })
  }
}
