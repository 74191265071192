
export const endpoint: any = {
	master:{
		city: `admin/api/city-by-state`,
		document: `admin/api/upload-document`,
	},
  wallet:{
    getWalletAmount:'vendor/api/vendor-wallet-balance'
  },
	login:{
		verifyLogin:`vendor/api/vendor-login`,
		verifyOtp:`vendor/api/vendor-verifyOtp`,
		logOut:`vendor/api/vendor-logout`,
		getUserType:`admin/api/get-admin-types`,
	},
	onboarding:{
		master: `admin/api/master-list`,
		business: `admin/api/agent-add`,
		stage: `admin/api/get-menu-stage`,
		document: `admin/api/get-agent-document`,
	},
	agent: {
		agentList: `admin/api/order/pending-order-list`,
    createUser:'vendor/api/create-user',
    getRate:'vendor/api/rate',
    currencyList: `vendor/api/currency/list`,
    favoriteCity: `api/city/quickshow`,
	}
,
  orderDetail:{
    getOrder:'vendor/api/order/get_order_list',
    sendApiReference:'vendor/api/refer-friend',
    updateOrder:'vendor/api/order/update_order',
    deleteOrder:'vendor/api/order/delete_order',
    getNames:'vendor/api/get_names',
    getPurposeList:'vendor/api/get_purposes_by_producttype',
    travellerRelationList:'vendor/api/get_relations',
    getPayeeNames:'vendor/api/get_payee_names'
 },
  order: {
    createOrder: `vendor/api/order/create_order`,
    stages: 'vendor/api/order/get_menu_stages',
    update_stage: 'vendor/api/order/update_menu_stage',
    resetStages:'vendor/api/order/reset_menu_stages',

  },
  travellerDetail:{
    getTraveller:'vendor/api/traveller/list',
    orderPurposes:'vendor/api/get_masters',
    updateTraveller:'vendor/api/update_traveller',
    cardExist:'vendor/api/check/cardno',
    addUpdateDeclaration:'vendor/api/traveller_declarations',
    removeDeclaration:'vendor/api/delete_declaration',
    getPayeeRelationList:'vendor/api/get_payee_list',
    getBankByName:'vendor/api/search-bank',
    getPurposeByResidance:'vendor/api/get_purposes_by_residency',


    getResidentialStatus:'vendor/api/get_residential_status',
    getBenefiaryType:'vendor/api/get_beneficiary_types'
  },
  travellerDocument:{
    getDocList: 'vendor/api/traveller/get-documents',
    getImageUrl: 'vendor/api/upload-file-url',
    saveDocument: 'vendor/api/traveller/save-documents'
  },
  deliveryDetails:{
    getDeliveryDetail:'vendor/api/delivery/list',
    getTimeSlots:'vendor/api/time/list',
    getStateCity:'vendor/api/state/city/list',
    addUpdateDelivery:'vendor/api/delivery/add/update',
    getSummeryDetail:'vendor/api/delivery-detail-summary'
  },
  orderProcessing:{
    getPaymentMethodList:'vendor/api/order-proceesing-list',
    getSummeryList:'vendor/api/order_processing_summary',
    addUpdatePayments:'vendor/api/save_payment_details',
    getBankMaster:'vendor/api/bank-list',
    generateDocumentLink:'vendor/api/generate_document_link',

    sendAadharOtp:'vendor/api/aadhar-send-otp',
    verifyAadharOtp:'vendor/api/aadhar-verify'
  },
  paymentDetail:{
    getPaymentDetails:'vendor/api/payment-list',
    checkLink:'vendor/api/send-link',
    sendPaymentLink:'vendor/api/send-payment-link',
    reqPayment:'vendor/api/get-initialPaymentLink',
    paymentReset:'vendor/api/save_payment_resp'
  },
  payeeDocument:{
    getPayeeDocuments:'',
  },
  orderList:{
    pendinOrderList:'vendor/api/my-orders',
    completeOrderList:'vendor/api/my-orders',
    cancelOrderList:'vendor/api/my-orders'
  },
  profile:{
    userProfile:'vendor/api/vendor-profile'
  },
  orderListActions:{
    cancelOrder:'vendor/api/cancel-orders'
  },
  dashBoard:{
    getDashBoardData:'vendor/api/dashboard-data',
    getOrderHistory:'vendor/api/get-order-history',
    getLiveRate:'vendor/api/get-live-rate',
    getCardData:''
  },

  accounts:{
    getVendorCommission:'vendor/api/vendor-commission',
    getVendorLedger:'vendor/api/vendor-ledger',
    getVendortdsCertificate:'vendor/api/vendor-tdsCertificates',
    getQuarter:'vendor/api/allquarters',
    getMonths:'vendor/api/allmonths',
    getInvoiceList:'vendor/api/vendor-invoices',
    getVendorBankList:'vendor/api/vendor-bank-transfer-list',
    transferBankAmount:'vendor/api/vendor-bank-transfer'
  }

}

