<div class="modal-content">
  <!-- Modal body -->
  <div class="modal-body text-center">
    <button type="button" class="close" (click)="_close()">&times;</button>
    <img src="assets/img/error.gif" alt="gif" class="mb-5" />
    <h4 class="mb-2">{{ data.title }}</h4>
    <p>
      {{ data.message }}
    </p>
    <ng-container
      *ngIf="data.actions?.confirm?.show || data.actions?.cancel?.show"
    >
      <div class="actions">
        <ng-container *ngIf="data.actions?.confirm?.show">
          <button class="btn mt-4">{{data.actions?.confirm?.label}}</button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
