import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, ViewEncapsulation, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MoneyConfirmationConfig } from '../confirmation.types';
import { MoneyConfirmationDialogService } from '../confirmation-dialog.service';

@Component({
  selector: 'money-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, MatDialogModule, NgClass],
})
export class MoneyDialogComponent {
  private _moneyService: MoneyConfirmationDialogService = inject(MoneyConfirmationDialogService);
  constructor(@Inject(MAT_DIALOG_DATA) public data: MoneyConfirmationConfig) {

  }

  _close(): void {
    this._moneyService._close();
  }
}
