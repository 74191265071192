import { shake } from "./shake";
import { bounce } from "./bounce";
import { SlideInOutAnimation } from "./slide";
import { collapse } from "./collapse";

export const moneyAnimations = [
  shake,
  bounce,
  SlideInOutAnimation,
  collapse
];
