import { NgClass, NgFor, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewChildren, QueryList, ElementRef, Renderer2, HostBinding } from '@angular/core';
import {  RouterModule } from '@angular/router';
import { moneyAnimations } from 'src/@money/animation';
// import { sideMenuInterface } from '../menu.constant';
@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgForOf, RouterModule, NgFor],
  animations: moneyAnimations,

})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @Input() item: any;
  @Input() depth: number;

  @ViewChildren('tagsSubMenuPanel') private _tagsSubMenuPanel: QueryList<ElementRef>;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;

  constructor(
    private _renderer2: Renderer2,
  ) {

  }

  ngOnInit(): void {
    // console.log(this.item);

  }


  onItemSelected(item: any) {
    if (!item.vendor_modules || !item.vendor_modules.length) {
      // this.router.navigate([item.route]);
      // this.navService.closeNav();
    }
    if (item.vendor_modules && item.vendor_modules.length) {
      this.expanded = !this.expanded;
    }

  }

}
