import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { DialogConfirmationConfig } from './dialog.type';
import { DialogComponent } from './dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private _matDialog: MatDialog = inject(MatDialog);
  private _defaultConfig: DialogConfirmationConfig = {
    title: 'Login Account',
    message: 'Hello, welcome back to Relimoney',
    dismissible: false,
  };
  constructor() { }

  login(config: DialogConfirmationConfig = {}): MatDialogRef<DialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'fuse-confirmation-dialog-panel',
    });
  }
}
