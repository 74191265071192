import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { City, Purpose } from './common.type';
import { FormGroup } from '@angular/forms';
import { CryptoUtils } from 'src/@money/crypto/crypto.utils';
import { CookieService } from 'ngx-cookie-service';
import { pickBy, toLower } from 'lodash-es';
import { MoneyConfirmationDialogService } from 'src/@money/services/confirmation/confirmation-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _cityName: ReplaySubject<City> = new ReplaySubject<City>(1);
  constructor(
    private _cookieService: CookieService,
    private _moneyConfirmationDialog: MoneyConfirmationDialogService,
  ) { }

  /**========================================================================
 **                            SETTER AND GETTER FOR USER LOGIN
 *========================================================================**/

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set city(value: City) {
    // Store the value
    this._cityName.next(value);
  }

  get city$(): Observable<City> {
    return this._cityName.asObservable();
  }
  // get readCity() {
  //   const cityList: any = CryptoUtils.decryptUsingAES(this._cookieService.get('agent_city_select'));
  //   return cityList ? JSON.parse(cityList) : [];
  // }
  /**========================================================================
   **                  SHOW ERROR ON INVALID FORM
   *========================================================================**/
  markFormGroupTouched(formGroup: FormGroup|any, isFocus?: any) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) this.markFormGroupTouched(control);
    });
    if (isFocus) {
      this.autoFocusControle(isFocus);
    }
  }
  autoFocusControle(isFocus: any) {
    const invalidElements = isFocus.nativeElement.querySelectorAll('.form-control.ng-invalid');
    if (invalidElements.length > 0) {
      window.scroll({ top: invalidElements[0].offsetTop - 200, behavior: 'smooth' })
      invalidElements[0].focus();
    }
  }
  changeCurrencyRate(req_type: string, rate: any, amount: any, round_off: number, product_id: number): any {
    if (product_id == 1) {
      if (req_type == 'INR') {
        if (Number(amount) < round_off) {
          return {
            inr_amont: (round_off * Number(rate)).toFixed(2),
            fx_amount: (round_off).toFixed(2),
            is_round_of: true
          }
        } else {
          const checkAmount: any = (Number(amount) / (round_off))
          const absoluteValue: any = parseInt(checkAmount).toString();
          return {
            inr_amont: (Number(absoluteValue) * round_off * Number(rate)).toFixed(2),
            fx_amount: (round_off * Number(absoluteValue)).toFixed(2),
            is_round_of: this.isFloat(checkAmount)
          }
        }
      } else {
        if (Number(rate) > Number(amount)) {
          return {
            inr_amont: (Number(rate)).toFixed(2),
            fx_amount: 1,
            is_round_of: true
          }
        } else {
          const checkAmount: any = (Number(amount) / Number(rate));
          if (checkAmount < round_off) {
            return {
              inr_amont: (parseInt(checkAmount.toString()) * Number(rate)).toFixed(2),
               fx_amount: (parseInt(checkAmount.toString())).toFixed(2),
                is_round_of: false }
          } else {
            const usd_ = checkAmount / round_off;
            return {
              inr_amont: (parseInt(usd_.toString()) * Number(rate) * round_off).toFixed(2),
              fx_amount: (parseInt(usd_.toString()) * round_off).toFixed(2),
              is_round_of: true
            }
          }

        }
      }
    } else {
      if (req_type == 'INR') {
        return {
          inr_amont: (Number(rate) * Number(parseInt(amount))).toFixed(2),
          fx_amount: (parseInt(amount)).toFixed(2),
          is_round_of: false
        }
      } else {
        if (Number(amount) < Number(rate)) {
          return {
            inr_amont: '',
            fx_amount: '',
            is_round_of: false
          }
        } else {
          const checkAmount: any = (Number(amount) / Number(rate))
          const absoluteValue: any = parseInt(checkAmount).toString();
          return {
            inr_amont: (Number(rate) * Number(absoluteValue)).toFixed(2),
            fx_amount: (Number(absoluteValue)).toFixed(2),
            is_round_of: false
          }
        }
      }
    }

  }

  private isFloat(value: any): boolean {
    if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return true;
    }

    return false;
  }

  getChangeRate(productId: number, productTypeId: number, rateList: any) {
    if (productId != 3 && (productTypeId == 2 || productTypeId == 5)) {
      return rateList.sell_rate
    } else {
      return rateList.buy_rate
    }
  }

  bindProductList(value: any) {
    const filterName = ['Currency Exchange', 'Forex Card', 'Money Transfer'];
    const filterData: any = { productList: [], productTypeList: [] }
    for (const key in value) {
      if (!key.includes("_")) {
        filterData.productList.push({ name: key, icon: toLower(key).split(" ").join(""), id: filterName.indexOf(key) + 1 });
        filterData.productTypeList.push(this.filterProduct(value, key)[key])
      }
    }
    return filterData
  }
  filterProduct(data: any, key: string) {
    return pickBy(data, function (v, k) {
      return k === key;
    });
  }
  productTypeNameIcon(productTypeList: any[], relationId: number): Purpose {
    return productTypeList?.filter((rel: any) => rel.id == relationId)[0] || {};
  }

  getValueObject(obj: any): any {
    let _valueObj: any = {};
    for (const key in obj.controls) {
      _valueObj[key] = obj.controls[key].value
    }
    return _valueObj;
  }
  _alertDeactivate(message?:string,dismissable?:boolean): void {
    const confirmation = this._moneyConfirmationDialog.open({
      title: 'Sorry',
      message: message?message:'This service is not available right now',
      dismissible:dismissable
    });
  }
  _alertMessage(message?:string): void {
    const confirmation = this._moneyConfirmationDialog.open({
      title: 'Delete product',
      message: message?message:'Are you sure you want to remove this product? This action cannot be undone!',
    });
  }
  chechFileSize(file:any){
    var maxfilesize = 2 * (1024 * 1024)  // 2 Mb
    var filesize = file.size;
    let msg=false;
    if (maxfilesize < filesize) {
      msg =true;
    }else{
      msg =false;
    }
    return msg;
  }
  getCurrentFYYear(){
    const result:any={};
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    result.previousYear=currentMonth >= 3 ? currentYear : currentYear-1;
    result.currentYear=currentMonth < 3 ? currentYear  : currentYear+1;
    result.currentYearLastDigit=Number(result.currentYear.toString().slice(-2));
    return result
  }
  addDays(theDate:Date, days:number) {
    return new Date(theDate.getTime() + days*24*60*60*1000);
  }

  removeDays(theDate:Date, days:number){
    return new Date(theDate.getTime() - days*24*60*60*1000);
  }

  getPurposeName(purposeId: number,purposesList:any): string {
    return purposesList.filter((p: any) => p.id == purposeId)[0].name;
  }

  getLastFiveYearArray():any[]{
    const currentYear = new Date().getFullYear();
    const yearList=[currentYear];
    for (let index = 1; index < 5; index++) {
      yearList.push(currentYear-index);
    }
    return yearList;
  }

  upperCase(data: string): string {
		const _string = data.split('_');
    let stringArray :any=_string.map(ele=>{return ele.charAt(0).toUpperCase()+ ele.slice(1)});
		return stringArray.join(' ');
	}
}
