<!-- <div class="dashboard clearfix" > -->
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-top">
        <div class="logo text-center">
          <img src="assets/img/logo.png" alt="logo" />
        </div>
        <hr class="gradient" />
        <div class="sidebar-menu">
          <app-menu-list-item
            *ngFor="let item of menuList"
            [item]="item"
          ></app-menu-list-item>
        </div>
      </div>
      <div class="sidebar-bot">
        <div class="logout" (click)="logout()">
          <div class="icon">
            <img src="assets/img/side/log.png" alt="icon" />
          </div>
          Log Out
        </div>
        <hr class="gradient" />
        <div class="profile">
          <div class="profile-img">
            <img src="assets/img/profile.png" alt="Img" />
          </div>
          <div class="profile-des">
            <span>{{tokenData.fullname}}</span><br />Agent
          </div>
        </div>
      </div>
    </div>
    <div class="side-toggle" (click)="sideToggle()">
      <i class="fas fa-angle-left"></i>
    </div>
  </div>
  <section class="dashboard-main-wrap">
    <dashboard-header></dashboard-header>
    <router-outlet></router-outlet>
  </section>
<!-- </div> -->
