import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/noAuth.guard';
import { NewOrderComponent } from './pages/module/new-order/new-order.component';
export const appRoutes: Route[] = [

	// Redirect empty path to '/dashboards'
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'dashboard/landing'
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		data: {
			layout: 'empty'
		},
		children: [
			{ path: 'sign-in', loadChildren: () => import('../app/pages/auth/login/login.routes') },
		]
	},
	// Admin routes
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver
    },
		data: {
			layout: 'home'
		},
		children: [
			// Dashboards
			{path: 'dashboard',

			children: [
				{path: 'landing', loadChildren: () => import('../app/pages/module/dashboard/dashboard-routes')},
				{path: 'onboarding', loadChildren: () => import('../app/pages/module/onboarding/onboarding-routes')},
				{path: 'order-transaction', loadChildren: () => import('../app/pages/module/order-transaction/order-transaction-route')},
        {path: 'new-order', component:NewOrderComponent},
        {path: 'order', loadChildren: () => import('../app/pages/module/traveller-order/travaller-order.routes')},
        {path: 'order-list', loadChildren: () => import('../app/pages/module/order-lists/order-list.routes')},
        {path: 'user-profile', loadChildren: () => import('../app/pages/module/user-profile/user-profile.routes')},
        {path: 'account', loadChildren: () => import('../app/pages/module/accounts/accounts.routes')},

			]},
		]
	},
];
