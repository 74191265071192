import { Injectable } from '@angular/core';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const fooCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 2000,
  touchendHideDelay: 100,
};

@Injectable({
  providedIn: 'root'
})

export class ToolTipService {

  constructor() { }
}
