<ul>
  <li [ngClass]="{ submenu: item.vendor_modules?.length }">
    <a
      (click)="onItemSelected(item)"
      [routerLink]="item.route ? [item.route] : []"
      routerLinkActive="active"
    >
      <div class="icon">
        <img src="assets/img/side/{{ item.icon }}.png" alt="icon" />
      </div>
      <div class="text">{{ item.menu }}</div>
      <ng-container *ngIf="item?.vendor_modules?.length">
        <div class="child-trigger">
          <i
            class="fas"
            [ngClass]="{ 'fa-angle-up': expanded, 'fa-angle-down': !expanded }"
          ></i>
        </div>
      </ng-container>
    </a>
    <ng-container *ngIf="expanded">
      <ul>
         <!-- [@slideInOut]="_toggleSubMenu == i ? 'in': 'out'" -->
        <app-menu-list-item
          *ngFor="let children of item.vendor_modules"
          [item]="children"
          [depth]="depth + 1"
        >
        </app-menu-list-item>
      </ul>
    </ng-container>
  </li>
</ul>



