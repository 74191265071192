import { Subject, takeUntil } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { DashboardHeaderComponent } from 'src/@money/components/header/dashboard-header/dashboard-header.component';
import { MenuListItemComponent } from 'src/@money/components/menu/menu-list-item/menu-list-item.component';
// import { sideMenu } from 'src/@money/components/menu/menu.constant';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/core/service/user/user.service';
import { CryptoUtils } from 'src/@money/crypto/crypto.utils';

@Component({
  selector: 'home-layout',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [NgFor, NgIf, NgClass, RouterModule, MenuListItemComponent, DashboardHeaderComponent],
})
export class HomeComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  protected menuList: any[] = [];
  protected _toggleSideMenu: boolean = false;
  tokenData:any;
  constructor(
    private _authService: AuthService,
    private _userService: UserService
  ){
  }

  ngOnInit(): void {
    this.tokenData = CryptoUtils.getItem('auth_token');
    // setTimeout(() => {
      this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res:any) =>{
        if(res){
          res.sideMenu.unshift({icon:"dashboard", id:0, menu: "Dashbord", route:"dashboard/landing"})
          this.menuList = res.sideMenu;
        }
      })
    // }, 600);
  }

  sideToggle(): void {
    this._userService.sideMenu = this._toggleSideMenu = !this._toggleSideMenu
  }
  logout():void{
    this._authService.signOut().subscribe(res => {
      if (res) {
        location.reload();
      }
    })
  }

  /**
     * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
