<div class="dashboard-header">
  <div class="row align-items-center">
    <div class="col dheader-left">
      <div class="d-block breadcrumb">
        <ul>
          <li><img src="assets/img/home-icon.png" alt="home" /></li>
          <li>Dashboard</li>
        </ul>
        <h4 class="mb-0">Onboarding</h4>
      </div>
    </div>
    <div class="col-auto dheader-right">
      <button type="button" [routerLink]="'dashboard/landing/live-rate'" class="btn live-btn"><img src="assets/img/live.png" alt="live" /> LIVE RATE</button>
      <div class="card wallet-card">
        <div class="img">
          <img src="assets/img/dashboard/wallet.png" alt="wallet" />
        </div>
        <div class="text">
          <span
            >Wallet Balance <img src="assets/img/dashboard/info.png" alt="info" class="ml-3"
          /></span>
          <h5>₹ {{wallet}}</h5>
        </div>
      </div>

      <!-- <div class="card wallet-card">
        <div class="img">
          <img src="assets/img/dashboard/wallet.png" alt="wallet" />
        </div>
        <div class="text">
          <span
            >FX INR Amount
            <img src="assets/img/dashboard/info.png" alt="info" class="ml-3"
          /></span>
          <h5>₹ {{wallet.fxInr}}</h5>
        </div>
      </div> -->
    </div>
  </div>
</div>
