import { Subject, takeUntil } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
	selector: 'dashboard-header',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.scss'],
	standalone: true,
  imports:[RouterModule]
})
export class DashboardHeaderComponent implements OnInit {
	private _unsubscribeAll: Subject<any> = new Subject<any>();
  protected wallet:any
	constructor(
    private _authSesvice:AuthService
	){
    this._authSesvice.walletAmount();
	}

	ngOnInit(): void {
    this._authSesvice._walletBalanceData.pipe(takeUntil(this._unsubscribeAll)).subscribe((bal: any) => {
      if (bal) {
        console.log(bal)
        this.wallet=bal.inr_amt;
      }
    })
	}
	/**
		* On destroy
	*/
	ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
	}
}
