import { Subject, filter, takeUntil } from 'rxjs';
import { Component, ViewEncapsulation, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { MoneyConfig } from 'src/@money/services/config/config.types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MoneyConfigService } from 'src/@money/services/config/config.service';
import { PlatformService } from 'src/@money/services/platform';
import { MONEY_VERSION } from 'src/@money/version/money-version';
import { EmptyComponent } from './empty/empty.component';
import { HomeComponent } from './home/home.component';
import { MoneyLoadingBarComponent } from 'src/@money/components/loading-bar/loading-bar.component';
import { UserService } from '../core/service/user/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, EmptyComponent, HomeComponent, MoneyLoadingBarComponent,NgClass],
})
export class LayoutComponent implements OnInit, OnDestroy {


  config: MoneyConfig;
  layout: string;
  theme: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('tagsSideMenuPanel') private _tagsSideMenuPanel: ElementRef;
  constructor(
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: any,
    private _renderer2: Renderer2,
    private _router: Router,
    private _moneyConfigService: MoneyConfigService,
    private _platformService: PlatformService,
    private _userService:UserService
  ) {

  }
  ngOnInit(): void {

    this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'money-version', MONEY_VERSION);
    this._renderer2.addClass(this._document.body, this._platformService.osName)

    // Subscribe to config changes
    this._moneyConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: MoneyConfig) => {
        // Store the config
        this.config = config;

        // Update the layout
        this._updateLayout();
      });

    // Subscribe to NavigationEnd event
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._unsubscribeAll),
    ).subscribe(() => {
      // Update the layout
      this._updateLayout();
    });
  }
  ngAfterViewInit(): void {
    this._userService.sideMenu$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res: boolean) => {
      const action = !res ? 'addClass' : 'removeClass';
      this._renderer2[action](this._tagsSideMenuPanel.nativeElement, 'full');
    })
  }
  /**
     * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }


  /**
    * Update the selected layout
    */
  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config
    this.layout = this.config.layout;

    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config
    const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout');
    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;
      if (this.config) {
        this.config.layout = layoutFromQueryParam;
      }
    }

    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
        // Set the layout
        this.layout = path.routeConfig.data['layout'];
      }
    });
  }
}
