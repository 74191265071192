import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { MoneyLoadingService } from '../services/loading';

@Directive({
  selector: '[button]',
  standalone: true
})
export class ButtonLoaderDirective {
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private loadingService: MoneyLoadingService
  ) {
    this.loadingService.show$.pipe().subscribe((res: boolean) => {
      this._checkButtonStatus(res);
    })
  }

  @HostListener('click', ['$event.target']) onClick(e: any) {
    if (e.classList.contains('btn')) {
      //this._checkButtonStatus();
    }
  }

  _checkButtonStatus(buttonStatus: boolean): void {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', buttonStatus);
    if (buttonStatus) {
      this.renderer.addClass(this.element.nativeElement, 'spinner');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'spinner');
    }
  }

}
