import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { PAGE_CONFIG, PageConfig } from './table.constant';

export type TableProviderConfig = {
  table?: PageConfig
}

export const provideTable = (config: TableProviderConfig): Provider | EnvironmentProviders => {
  return [
    {
      provide: PAGE_CONFIG,
      useValue: config,
      multi: true,
    },
  ];
};

