import { BehaviorSubject, Observable, catchError, of, switchMap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { endpoint } from 'src/app/core/service/endpoints';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TravellerOrderService {
  onStageChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  showOrderStages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetOrderStages: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private _httpClient: HttpClient
  ) { }

  _getOrderStage(obj?: { order_id: number }): Observable<any> {
    return this._httpClient.post(`${endpoint?.order.stages}`, obj).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  _updateOrderStage(obj?: { order_id: string|number, menu_stage: number }): Observable<any> {
    return this._httpClient.post(`${endpoint?.order.update_stage}`, obj).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }



  /**
   * Setter for stage
 */
  set stage(value: any) {
    this.onStageChange.next(value);
  }

  /**
   * getter for stage
   */
  get stage$(): Observable<any> {
    return this.onStageChange.asObservable();
  }


  /**
   * Setter for sideMenu
 */


  _removeOrderStages(obj?: { order_id: number }): Observable<any> {
    return this._httpClient.post(`${endpoint?.order.resetStages}`, obj).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  checkCityList(credentials?: { id: number }): Observable<any> {
    return this._httpClient.post(endpoint.agent.favoriteCity, null).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  _checkCurrencyList(obj?: { product_id: number,product_type_id:number,city_id:number}): Observable<any> {
    return this._httpClient.post(`${endpoint?.agent.currencyList}`, obj).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }
  _checkCurrencyRate(payload: any): Observable<any> {
    return this._httpClient.post(`${endpoint.agent.getRate}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

  _createUser(payload: {name:string,  mobile:string}): Observable<any> {
    return this._httpClient.post(`${endpoint.agent.createUser}`, payload).pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        return of(response);
      }),
    );
  }

}
