import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MoneySnackbarConfig } from './snackbar.types';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { merge } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private _matSnackBar: MatSnackBar = inject(MatSnackBar);
  private _defaultConfig: MoneySnackbarConfig = {
    message: 'Something went wrong! Please try after sometime',
    body: {
      background: 'success'
    },
    panelClass: 'snack-notification',
    duration: 0,
    horizontalPosition: 'center',
    verticalPosition: 'bottom'
  };
  constructor() { }

  private open(config: MoneySnackbarConfig = {}): MatSnackBarRef<SnackbarComponent> {
    const userConfig: any = merge({}, this._defaultConfig, config);
    return this._matSnackBar.openFromComponent(SnackbarComponent, {
      data: { message: userConfig.message, class: userConfig.body.background },
      panelClass: [userConfig.panelClass],
      horizontalPosition: userConfig.horizontalPosition,
      verticalPosition: userConfig.verticalPosition,
      duration: userConfig.duration
    });
  }

  success(message: string, duration?: number) {
    const config: any = {
      message: message,
      duration: duration ?? 6000,
      body: {
        background: 'success'
      }
    }
    this.open(config);
  }

  error(message: string, duration?: number) {
    const config: any = {
      message: message,
      duration: duration ?? 6000,
      body: {
        background: 'error'
      }
    }
    this.open(config);
  }

  warning(message: string, duration?: number) {
    const config: any = {
      message: message,
      duration: duration ?? 6000,
      body: {
        background: 'warning'
      }
    }
    this.open(config);
  }
}
